import emailjs from 'emailjs-com';
import { Box, Typography, Stack, Grid, Divider, Button, Avatar, Link, Grow, Fade, SpeedDial, SpeedDialAction, Container, CardMedia, Card, CardActionArea, CardContent, CardActions } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import CallIcon from '@mui/icons-material/Call';
import BuildIcon from '@mui/icons-material/Build';
import DataObjectIcon from '@mui/icons-material/DataObject';
import GroupIcon from '@mui/icons-material/Group';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import darkTheme from './theme/darkTheme';
import CssBaseline from '@mui/material/CssBaseline';
import avatar from './Avatar.png'
import logo from './logo.svg'
import wordingLogo from './logo_wording.svg'
import projects from './projects';
import { useHttpClient } from './shared/hooks/http-hook';
import { useWindowSize } from './shared/hooks/window-hook';
import LoadingSpinner from './shared/components/LoadingSpinner';
import ErrorModal from './shared/components/ErrorModal';
import CookiesBanner from './shared/components/CookiesBanner';
import { palette } from '@mui/system';
import Input from './shared/components/Input';
import { VALIDATOR_MAXLENGTH, VALIDATOR_REQUIRE, VALIDATOR_EMAIL } from './shared/util/validators';
import { useForm } from './shared/hooks/form-hook';
import MioModal from './shared/components/MioModal';
import * as astro from './astro.json';
import Lottie from 'react-lottie';
import Cookies from 'universal-cookie';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import SubjectCard from './shared/components/SubjectCard';


const astroOptions = {
    loop: true,
    autoplay: true,
    speed: 1,
    animationData: astro,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const actions = [
    { icon: <CallIcon />, name: 'contact' },
    { icon: <GroupIcon />, name: 'about' },
    { icon: <DataObjectIcon />, name: 'blog' },
    { icon: <BuildIcon />, name: 'projects' },
];

const App = () => {
    const [open, setOpen] = React.useState(false);
    const form = React.useRef()
    const [formState, inputHandler] = useForm({
        name: {
            value: "",
            isValid: false
        },
        email: {
            value: "",
            isValid: false
        },
        message: {
            value: "",
            isValid: false
        }
    }, false)
    const handleOpen = () => setOpen(true);
    const handleClose = (e) => {
        const element = document.getElementById(e.currentTarget.id + "-label");
        const IdToPick = element.innerHTML;
        const myElement = document.getElementById(IdToPick);
        myElement?.scrollIntoView()
        setOpen(false)

    }

    const [errorMessage, setErrorMessage] = React.useState();
    const [mailError, setMailError] = React.useState(false);
    const [mailSuccess, setMailSuccess] = React.useState(false);
    const [loadedPosts, setLoadedPosts] = React.useState();
    const { isLoading, error, clearError, sendRequest } = useHttpClient();
    const [mailLoading, setMailLoading] = React.useState(false);
    const size = useWindowSize(true)
    const checked = true;
    const [isCookies, setIsCookies] = React.useState(false);
 
const acceptCookies = () => {
    const cookies = new Cookies();
    cookies.set('MIO-WEB', {accept: true}, { path: '/' });
    setIsCookies(true);
  }
  
  React.useEffect(()=>{
    window.scrollTo(0,0);
  
  const getCookies = () => {
    const cookies = new Cookies();
    let myCookies = cookies.get('MIO-WEB');
    if(!myCookies){
      setIsCookies(false)
    }else{
      setIsCookies(true)
    }
    
  
  }
  
  getCookies()
  },[])


    const subjects = [
        {
            id: "37985",
            user: "ZzzSleepyJay",
            age: "~ 7,41M",
            email: "m00n@miomideal.com",
            profession: "Design Dealer",
            race: "???",
            location: "Moon",
            img:"https://cdn.discordapp.com/attachments/1006415648680837140/1026959749633286184/Ol_Dirty_Jay_Monkey_in_Space_suit_hyper_realistic_Photo_Ultra_h_ffa144e6-5377-4b3a-ab4f-c7f563210cf5.png",
            rank:"Partner"

        },
        {
            id:"59880",
            user: "R4$0N3",
            age: "undefined",
            email: "m4r5@miomideal.com",
            profession: "Coding Ninja",
            race: "Marsian",
            location: "Mars",
            img:"https://cdn.discordapp.com/attachments/1021587013314551859/1026265403887992852/Rasone_Otter_in_Space_suit_hyper_realistic_Photo_Ultra_high_qua_ab46dc79-a892-4fae-8fed-456a9d4e1b87.png",
            rank:"Partner"
        }
    ];
    const createSubjects = (data,index) => {
        return <SubjectCard
                id={data.id}
                user={data.user}
                age={data.age}
                email={data.email}
                profession={data.profession}
                race={data.race}
                location={data.location}
                img={data.img}
                rank={data.rank}


        />
    }

    const createProjects = (data, index) => {

        let cardStyle = { p: 1, display: "flex", flexDirection: 'row', height: "100%", width: "100%", alignItems: 'center' }

        if (index % 2 === 0) {
            cardStyle = { p: 1, display: "flex", flexDirection: 'row-reverse', height: "100%", alignItems: 'center' }
        }
        if (size.width < 380) {
            cardStyle = { p: 1, display: "flex", flexDirection: 'column', height: "100%", alignItems: 'center' }
        }
        return (
            <Grid item xs={12} key={index}>
                <Card elevation={0} id={data.id} sx={cardStyle}>
                <Grid container rowSpacing={2}>

                    <Grid item sm={6} sx={{margin:"auto 0"}} >
                        <Link target="_blank" href={data.url}>
                            <CardActionArea><CardMedia sx={{ aspectRatio: "16 / 9" }} component="img" image={data.image} alt={data.title} width="100%" /></CardActionArea>
                        </Link>
                    </Grid>
                    <Grid item sm={6}>
                        <CardContent sx={{ width: "100%" }}>
                            <Typography sx={{ my: 2, }} variant='h5' color="primary">{data.title.toUpperCase()}</Typography>
                            <Divider sx={{ my: 2 }}>{data.year}</Divider>
                            <Typography variant='body1'>{data.desc}</Typography>

                        </CardContent>

                        <CardActions>

                            {data.src &&
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" color="warning"><Link target="_blank" underline="none" color="white" rel='noreferer' href={data.src}>SOURCE</Link></Button>
                                    <Button variant="contained" color="success"><Link target="_blank" underline="none" color="white" rel='noreferer' href={data.url}>LIVE</Link></Button>
                                </Stack>}
                            {!data.src &&
                            <Box>
                                <Button variant="contained" color="success"><Link target="_blank" underline="none" color="white" rel='noreferer' href={data.url}>LIVE</Link></Button>

                            </Box>
                            }
                        </CardActions>

                    </Grid>
                </Grid>
                </Card>
            </Grid>
        )

    }
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const responsePosts = await sendRequest('https://blogai.miomideal.com/api/posts');
                const filteredPosts = responsePosts.posts.filter(p => p.category.title === "Published").reverse();
                setLoadedPosts(filteredPosts);
            } catch (e) { }
        }
        fetchData()
    }, [sendRequest])

    const contactFormSubmitHandler = async event => {
        event.preventDefault();
        setMailLoading(true);
        await emailjs.send("MIOMIDEAL", "mio_template", {
            from_name: "Miomideal",
            message: formState.inputs.message.value,
            reply_to: formState.inputs.email.value,
            email: formState.inputs.email.value,
            name: formState.inputs.name.value,

        }, "GKOU3nF2ntTub8sIe").then(
            (result) => {
                setMailLoading(false);
                setMailSuccess(true)
            },
            (error) => {
                setMailError(true)
                setErrorMessage(error)
            });



    }

    const headerStyle = {
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",

    }
    
    return <>
        <ThemeProvider theme={darkTheme}>

            <CssBaseline />
            <ErrorModal error={error || errorMessage} onClear={clearError || setMailError} />
            <CookiesBanner
      isCookies={!isCookies}
      setCookies={acceptCookies}
          /> 
            {isLoading && <LoadingSpinner />}
            {mailLoading && <LoadingSpinner />}
            {!isLoading && !mailLoading && <SpeedDial
                ariaLabel="SpeedDial tooltip"
                sx={{ '& .MuiFab-primary': { backgroundColor: palette.error, color: 'fde3ca' }, position: 'fixed', bottom: 16, right: 16, }}
                icon={<NearMeIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={handleClose}
                    />
                ))}
            </SpeedDial>}

            {isCookies && !isLoading && !mailLoading && <Container sx={{ my: 2, p: 0 }}>
                <Container maxWidth='xl' component="header" style={headerStyle}>
                    <Box sx={{ margin: "auto" }}>
                        <Grid container direction={{ xs: "column-reverse", md: "row" }} spacing={4}>
                            <Grid item sm={6}>
                                <Fade in={checked} {...(checked ? { timeout: 500 } : {})}><Box sx={{ display: "flex", my: 4, justifyContent: "center", alignItems: "center", width: "100%", }}>
                                    <Avatar variant="square" sx={{ width: "80%", height: "80%" }} src={wordingLogo} />
                                </Box>
                                </Fade>
                                <Grow in={checked}><Typography color="primary" variant="h4" >Together we develop our future.</Typography></Grow>
                                <Fade in={checked}><Divider sx={{ my: 2 }} /></Fade>
                                <Grow in={checked} style={{ transformOrigin: '0 0 0' }} {...(checked ? { timeout: 3000 } : {})}><Typography sx={{ my: 1}} variant="h4">Feel free to discover our page.</Typography></Grow>
                                <Grow in={checked} style={{ transformOrigin: '0 0 0' }} {...(checked ? { timeout: 2000 } : {})}><Typography sx={{ my: 3 }} variant="h6">Designed with ❤️ Based in Saarbrücken</Typography></Grow>
                                <Grow in={checked} style={{ transformOrigin: '0 0 0' }} {...(checked ? { timeout: 4000 } : {})}><Button href="https://vcard.miomideal.com/" variant='contained' color="error">Contact</Button></Grow>
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} item sm={6}>
                                <Fade in={checked} {...(checked ? { timeout: 4000 } : {})}><Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", aspectRatio: "1 / 1", }}>
                                    <Avatar sx={{ width: "80%", height: "80%" }} src={avatar} />
                                </Box>
                                </Fade>
                            </Grid>

                        </Grid>
                    </Box>
                </Container>
                <Container component="main" maxWidth="xl" sx={{ margin: "2rem auto", p: 0, minHeight: "50vh", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>

                    <Container sx={{ marginBottom: "4rem" }} component="section" id="projects" maxWidth="xl">
                        <Typography sx={{ my: 4 }} color="error" variant='h2'>
                            Featured Projects
                        </Typography>
                        <Grid container spacing={2}>
                            {projects?.map(createProjects)}
                        </Grid>
                    </Container>

                    <Container sx={{ marginBottom: "4rem" }} component="section" id="blog" maxWidth="xl">
                        <Typography sx={{ my: 4 }} color="error" variant='h2'>
                            Latest Blogposts
                        </Typography>
                        <Grid container spacing={2}>
                            {loadedPosts?.slice(0, 3).map(createPosts)}
                        </Grid>
                        <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                            <Button sx={{ my: 4 }} color="error" variant='contained'>
                                <Link target="_blank" underline="none" color="white" rel='noreferer' href="https://blog.miomideal.com">Read all Articles</Link>
                            </Button>
                        </Box>
                    </Container>

                    <Container sx={{ marginBottom: "4rem" }} component="section" id="about" maxWidth="xl">
                        <Box sx={{ display: "flex", my: 4, justifyContent: "center", alignItems: "center", width: "100%", }}>
                            <Avatar variant="square" sx={{ width: "50%", height: "50%" }} src={wordingLogo} />
                        </Box>
                        <Typography sx={{ my: 4 }} color="error" variant='h2'>
                            About
                        </Typography>
                        <Grid container spacing={2} >
                            {subjects.map(createSubjects)}
                        </Grid>
                    </Container>
                    <Container sx={{ marginBottom: "4rem" }} component="section" id="contact" maxWidth="xl">
                        <MioModal
                            open={mailSuccess}
                            close={() => setMailSuccess(false)}
                            header={"Success!"}
                            footer={<>
                                <Box>
                                    <Button variant='contained' color="success" onClick={() => setMailSuccess(false)}>Niice!</Button>
                                </Box>
                            </>}
                        >
                            <Typography variant="text">Your Message has been send</Typography>
                        </MioModal>

                        <Typography sx={{ my: 4 }} color="error" variant='h2'>
                            Contact
                        </Typography>
                        <Grid container spacing={2} >
                            <Grid item sx={{ width: "100%" }} sm={6}>
                                <Card>
                                    <form id="contact-form" ref={form} style={{ width: "100%", padding: "1rem" }} onSubmit={contactFormSubmitHandler}>
                                        <Input
                                            id="name"
                                            label="Full Name"
                                            type="text"
                                            onInput={inputHandler}
                                            validators={[VALIDATOR_REQUIRE()]}
                                            helperText={"Please enter your name."}
                                        />
                                        <Input
                                            id="email"
                                            label="E-Mail"
                                            type="email"
                                            onInput={inputHandler}
                                            validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
                                            helperText={"Please enter a valid e-mail adresse."}
                                        />
                                        <Input
                                            id="message"
                                            label="Message"
                                            type="text"
                                            onInput={inputHandler}
                                            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(200)]}
                                            helperText={"Please enter your message."}
                                            multiline={true}
                                            rows="3"

                                        />

                                        <Button disabled={!formState.isValid} color="success" type='submit' variant='contained'>Submit</Button>
                                        {mailError && <p>{mailError}</p>}
                                    </form>
                                </Card>
                            </Grid>
                            <Grid item sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} sm={6}>
                                <Lottie
                                    options={astroOptions}
                                    height={300}
                                    width={300}
                                />

                            </Grid>
                        </Grid>
                    </Container>
                </Container>
                <Box component="footer" sx={{ display: "flex", flexDirection:"column", alignItems: "center", justifyContent: "center" }}>
                    <Stack direction="row" sx={{ width: "250px" }} spacing={2} justifyContent="space-evenly">
                        <InstagramIcon fontSize='large' />
                        <Box onClick={() => window.scrollTo(0, 0)} sx={{ display: "flex", justifyContent: "center", height: "50px", alignItems: "center" }}>
                            <img style={{ width: "100%", height: "100%", maxHeight:"45px" }} src={logo} alt="logo" />
                        </Box>
                        <TwitterIcon fontSize='large' />
                    </Stack>
                    <Typography style={{margin:"1rem 0", backgroundImage:
                    "radial-gradient( circle farthest-corner at 10% 20%,  rgba(222,168,248,1) 0%, rgba(168,222,248,1) 21.8%, rgba(189,250,205,1) 35.6%, rgba(243,250,189,1) 52.9%, rgba(250,227,189,1) 66.8%, rgba(248,172,172,1) 90%, rgba(254,211,252,1) 99.7% )",
                    color:"transparent",
                    WebkitBackgroundClip:"text"}}>© {getYear()} Mio Mideal</Typography>
                </Box>
            </Container>}

        </ThemeProvider>
    </>
}

const getYear = () => {
    const year = new Date().getFullYear();
    return year
}



const createPosts = (data, index) => {
    let cardStyle = { p: 1, display: "flex", flexDirection: 'column', height: "100%" }
    const url = "https://blog.miomideal.com"
    const date = new Date(data.createdAt).toLocaleDateString()
    return (
        <Grid item xs={12} sm={4} key={index}>
            <Card elevation={0} id={data.id} sx={cardStyle}>
                <Box sx={{ flex: 1 }} >
                    <Link target="_blank" href={data.url}>
                        <CardActionArea><CardMedia sx={{ aspectRatio: "16 / 9" }} component="img" image={data.image} alt={data.title} width="100%" /></CardActionArea>
                    </Link>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <CardContent>
                        <Typography sx={{ my: 2 }} noWrap variant='h5' color="primary"> {data.title.toUpperCase()}</Typography>
                        <Divider sx={{ my: 2 }}>{date}</Divider>
                        <Typography variant='body1'>{data.content.slice(0, 100) + ' ...'}</Typography>

                    </CardContent>

                    <CardActions>
                        <Button variant="contained" color={"secondary"}><Link target="_blank" underline="none" color="white" rel='noreferer' href={url + `/#/${data.id}`}>READ MORE</Link></Button>
                    </CardActions>

                </Box>

            </Card>
        </Grid>
    )

}
export default App;