import { v4 as uuidv4 } from 'uuid'; 
function createData(id, title, url,src, desc, year,image) {
    return { id, title, url,src, desc, year, image };
  }
  
  const projects = [
    createData(uuidv4(), 'tappen.app', "https://www.tappen.app","https://github.com/TheRascaI/tappen-app", "Tappen is a social game we developed focusing communication within a small group, a party or wherever you want to play it. To encourage communication during gameplay we have come up with a bunch of snappy questions for you. Give it a try and make yourself your own picture of tappen. ( IN DEVELOPMENT )", 2019, "https://cdn.discordapp.com/attachments/1008341003901599866/1024134583538700288/Bildschirmfoto_2022-09-27_um_03.44.52.png"),
    createData(
      uuidv4(),
      //title
      'Shutterstock',
      //link
      "https://www.shutterstock.com/de/g/Mio+Mideal",
      //src
      null,
      //desc
      "In 2022 due to the rise of Artificial Intelligence, the technology became famous for the first time as an art generation tool. We at Mio Mideal started to develop concepts for creating Stock footage with so-called Stable-diffusion tools online. You can check out our Stock footage here.",
      //year
      2022,
      //image
      "https://cdn.discordapp.com/attachments/1008341003901599866/1025788727945076798/shutter.jpg"
      ),
        createData(
      uuidv4(),
      //title
      'Dalle AI Image Generator',
      //link
      "https://ai-images.miomideal.com",
      //src
      "https://github.com/R4s0n3/dalle-2-playground",
      //desc
      "We build an AI-Image generator powered by Open Ai's Dalle-2.",
      //year
      2022,
      //image
      "https://openai.com/content/images/2022/05/twitter-1.png"
      ),
    createData(
      uuidv4(),
      'Adobe Stocks', "https://stock.adobe.com/de/contributor/211103572/Mio%20Mideal", null ,"In 2022 due to the rise of Artificial Intelligence, the technology became famous for the first time as an art generation tool. We at Mio Mideal started to develop concepts for creating Stock footage with so-called Stable-diffusion tools online. Check out our Stock footage here.", 2022, "https://cdn.discordapp.com/attachments/1008341003901599866/1025788727534039140/adobe.png"),
    // createData(uuidv4(), 'Mio Mideal Tips', "https://tips.miomideal.com", null ,"We at Mio Mideal love creating stuff and we also love to share what we created to the people. One of our customer related projects are our Filter-Tips Packs we designed at our company. They are funny as an accessoire for your desk plus they are just looking wild when they are rolled into a paper. Check em out on our website.", 2022, "https://cdn.discordapp.com/attachments/1008341003901599866/1024155660348313631/Bildschirmfoto_2022-09-27_um_05.08.29.png"),
    createData(uuidv4(), 'Mio Mideal Portfolio', "https://www.miomideal.com","https://github.com/TheRascaI/portfolio" ,"We build our own Portfolio with the Power of MERN. Take a look under it's hoo, we published the code online. You can check out or latest blog posts or online activities here. Give it a shot and keep in touch! Have fun scrolling our page, we hope you enjoy.", 2022, "https://cdn.discordapp.com/attachments/1008341003901599866/1026257905877516379/Bildschirmfoto_2022-10-03_um_00.22.05.png"),
];
  
  export default projects;